/* eslint-disable no-restricted-globals */
import React from 'react';
import { render } from 'react-dom';
//import './assets/fonts/Ginóra-Sans-Light.otf'
//import './assets/fonts/Ginóra-Sans-Regular.otf'
//import './assets/fonts/Ginóra-Sans-Semi-Bold.otf'

import App from './App';

render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
