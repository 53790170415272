import React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Download from './pages/Download';
import Donate from './pages/Donate';
import Instagram from './pages/Instagram';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/download" component={Download} />
        <Route path="/donate" component={Donate} />
        <Route path="/apoiar" component={Donate} />
        <Route path="/instagram" component={Instagram} />
      </Switch>
    </BrowserRouter>
  );
}
