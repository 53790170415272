import React, { useEffect } from 'react';
import axios from 'axios';
import './styles.css'
import profilePic from '../../assets/images/pic.jpg'
import verified from '../../assets/images/verified-48.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAmazon,
  faAppStoreIos,
  faGithub,
  faGooglePlay,
  faInstagram,
  faLinkedinIn
} from '@fortawesome/free-brands-svg-icons'


//const jarvisbotUrl = 'http://44.195.176.97:3199/v1/notifications';
const jarvisbotUrl = 'https://jarvis.shiftbyte.com.br/v1/notifications';

const socialUrl = {
  linkedin: 'https://www.linkedin.com/in/rafaeljustinox/',
  github: 'https://github.com/rafaeljustinox',
  playstore: 'https://play.google.com/store/apps/dev?id=5032620316957929781',
  appstore: 'https://apps.apple.com/br/developer/rafael-justino/id1553787705',
  amazon: 'https://www.amazon.com.br/s?k=Shiftbyte&i=alexa-skills',
  instagram: 'https://www.instagram.com/justinotech/',
}

export default function Home({ history }) {

  async function sendPresence(subcontext = '') {
    const data = {
      "type": "presence",
      "context": "rafaeljustino.com" + subcontext
    }
    try {
      await axios.post(jarvisbotUrl, data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    sendPresence();
  })

  function openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  async function onClickButton(service) {
    sendPresence(`/${service}`);
    openInNewTab(socialUrl[service]);
  }

  function renderSocialButton(name, icon) {
    return (
      <>
        <button className="btn circular-btn" onClick={() => onClickButton(name)}>
          <div className="btn-content">
            <FontAwesomeIcon icon={icon} />
          </div>
        </button>
      </>
    );
  }

  return (
    <>
      <div id="body-container">
        <main id="main-container">
          <div className="profile">
            {/* Profile Photo */}
            <div className="circle-avatar">
              <img src={profilePic} id="photo" alt="Rafael Justino" />
            </div>
            <div className="title">
              <div className="name-and-badge">
                <span>
                  Rafael <strong>Justino</strong>
                </span>
                <img src={verified} id="verified" alt="Verified" />
              </div>
              <h4>Software Engineer</h4>
              <div className="country" ><h5>🇧🇷 Brazil</h5></div>
            </div>
          </div>
          <div id="social-buttons" className="social-buttons-horizontal">
            {renderSocialButton('linkedin', faLinkedinIn)}
            {renderSocialButton('github', faGithub)}
            {renderSocialButton('playstore', faGooglePlay)}
            {renderSocialButton('appstore', faAppStoreIos)}
            {renderSocialButton('amazon', faAmazon)}
          </div>
        </main>
      </div>
    </>
  );
}
